import SectionTitleEle from "./SectionTitleEle"
import Flickity from 'react-flickity-component'
import CredsEle from "./CredsEle"
import { useRef,useState, useEffect } from 'react';




function SliderImageEle({imgUrl})
{
    return(
            <div className="slider_ele">
                <img src={imgUrl} alt="slider Element"/>
            </div>
    )
}


function Slider({playersData,onSetSlideIndex,sliderImagesRows})
{
    useEffect(()=>{CheckCurrentIndex()});
    
    const flkty = useRef(null);

    let flickityOptions = {
        initialIndex: 1,
        wrapAround:false,
        pageDots:false,
        cellAlign:'right',
        prevNextButtons:false
    }
    if( window.innerWidth<1024)
    {
        flickityOptions = {
            initialIndex: 1,
            wrapAround:false,
            pageDots:false,
            cellAlign:'left',
            prevNextButtons:false
        }
    }
    function CheckCurrentIndex()
    {
        console.log("THIS IS GETTING CALLED");
        
        const onPageLoad = () => {
            //setPlayAnimation(true);
            console.log("BUT THIS IS NOT");
            if( flkty.current!==null)
            {
                flkty.current.on('change', () => {
                
                    let slide_index = flkty.current.selectedIndex;
                   
                    onSetSlideIndex(slide_index);
                  })     
            }
            

            
          };

        if (document.readyState === 'complete') {
            onPageLoad();
          } else {
            window.addEventListener('load', onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
          }      

    }

    function SlideNext()
    {
        flkty.current.next();
    }
    function SlidePrev()
    {
        flkty.current.previous();
    }
    return(
        <>
            <Flickity
                flickityRef={c => flkty.current = c}
                className={'carousel flikity-carasouel'} // default ''
                elementType={'div'} // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={false} // default false
            
                reloadOnUpdate={false} // default false
                static={true} // default false
            >
                {sliderImagesRows}
                
            </Flickity>
            <div className="slider_paginationBar flex_grid">
                <div className="slider_pag_ele slider_pag_prev_btn desk_only" onClick={SlidePrev}>
                    <img src="/images/slide_pag_ico.png" alt="" />
                </div>
                <div className="slider_pag_ele slider_pag_next_btn" onClick={SlideNext}>
                    <img src="/images/slide_pag_ico.png" alt="" />
                </div>
            </div>
        </>
        
    )
         
}







export default function MeetSquad({playersData})
{

    
    const [slideIndex,setSlideIndex] = useState(1);
    const sliderImagesRows = playersData.map( (playerData,index)=>{
            return(
                <SliderImageEle key={playerData.id} imgUrl={playerData.sliderImageUrl} />
            )
    });
    const queryString = window.location.href;
    
    useEffect(()=>{scrollToVal()},);

    function scrollToVal()
    {
        if(queryString.indexOf("#meet_squad") !== -1)
        {
            const ele = document.getElementById("meet_squad");
            if(ele!==null)
            {
                ele.scrollIntoView({ behavior: 'smooth' });;
            }
        }
    }
    
    // const ele = document.getElementById("meet_squad");
    // if(ele!=null)
    //     {
    //         ele.scrollIntoView({ behavior: 'smooth' });;
    //     }
    function onSetSlideIndex(index)
    {
        console.log("SETTING SLIDE INDEX");
        setSlideIndex(index);

    }

    let name = playersData[slideIndex].name;
    let biography = playersData[slideIndex].biography;
    let height = playersData[slideIndex].height;
    let main = playersData[slideIndex].main;
    let age = playersData[slideIndex].age;
    let matches = playersData[slideIndex].matches;
    let runs = playersData[slideIndex].runs;
    let wickets = playersData[slideIndex].wickets;
    let position = playersData[slideIndex].position;

   /* playersData.map(player=>{
                                console.log(player.height);
    })
*/
    return(
        <>
            <section id="meet_squad" className="MS_total_container">
                <section className="MS_info_container  base_paddings ">
                    <div className="max_content center_aligned  MS_parent ">
                        <SectionTitleEle >Meet the Management</SectionTitleEle>
                        <div className="MS_logo_content_parent flex_grid">

                            <div className="bg_eagle_logo" style={{ backgroundImage: `url("./images/bg_logo.png")` }} >
                            
                            </div>

                            <div className="player_content">
                                <div className="player_name">
                                    <h5 className="oswald-bold h3 brand_orange">{name}</h5>
                                </div>
                                <div className="player_creds">
                                    {/* <p className="roboto-condensed-regular p3 white_color">{main} <span className="desk_inLine_only">|</span><br className="mob_only"/> {age} yrs | {height} </p> */}
                                    <p className="roboto-condensed-regular p3 white_color">{position} </p>
                                
                                </div>
                                <div className="player_bio desk_only" >
                                    <div className="player_bio_title">
                                        <h6 className="roboto-condensed-bold p2 white_color">Short Biography</h6>
                                    </div>
                                    <p className="roboto-condensed-regular p3 white_color">{biography} </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="MS_slider_parent ">
                    <div className="  slider_extraInfo_container flex_grid">
                        <div className="slider_container">
                            <Slider playersData={playersData} onSetSlideIndex={onSetSlideIndex} sliderImagesRows={sliderImagesRows} />
                        </div>
                        <div className="player_match_creds ">
                            <div className="credsContainer flex_grid">
                                
                                <div className="credsContainer_grph">
                                    <svg className="credsContainer_ico" width="55" height="37" viewBox="0 0 55 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.89204 5.0176C4.95492 4.43289 3.72124 4.71858 3.13653 5.6557C2.55182 6.59281 2.83751 7.8265 3.77463 8.41121L5.89204 5.0176ZM22.4 17.675L21.3413 19.3718L21.3413 19.3718L22.4 17.675ZM32.6 17.675L33.6587 19.3718L33.6587 19.3718L32.6 17.675ZM51.2254 8.41108C52.1625 7.82637 52.4482 6.59269 51.8635 5.65557C51.2788 4.71845 50.0451 4.43278 49.108 5.01749L51.2254 8.41108ZM3.77463 8.41121L21.3413 19.3718L23.4587 15.9782L5.89204 5.0176L3.77463 8.41121ZM21.3413 19.3718C25.0115 21.6618 29.9885 21.6618 33.6587 19.3718L31.5413 15.9782C29.1669 17.4597 25.8331 17.4597 23.4587 15.9782L21.3413 19.3718ZM33.6587 19.3718L51.2254 8.41108L49.108 5.01749L31.5413 15.9782L33.6587 19.3718ZM7.66667 4H47.3333V0H7.66667V4ZM47.3333 4C49.7187 4 51 5.54399 51 6.71429H55C55 2.67732 51.2072 0 47.3333 0V4ZM51 6.71429V30.2857H55V6.71429H51ZM51 30.2857C51 31.456 49.7187 33 47.3333 33V37C51.2072 37 55 34.3227 55 30.2857H51ZM47.3333 33H7.66667V37H47.3333V33ZM7.66667 33C5.28131 33 4 31.456 4 30.2857H0C0 34.3227 3.79279 37 7.66667 37V33ZM4 30.2857V6.71429H0V30.2857H4ZM4 6.71429C4 5.54399 5.28131 4 7.66667 4V0C3.79279 0 0 2.67732 0 6.71429H4Z" fill="white"/>
                                    </svg>

                                    <p className="roboto-condensed-regular p3 white_color">Email</p>
                                </div>

                                <p className="credsContainer_para roboto-condensed-regular p3 white_color">{playersData[slideIndex].email}</p>
                            </div>
                            
                            <div className="credsContainer flex_grid">
                                
                                <div className="credsContainer_grph">
                                    <svg  className="credsContainer_ico" width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M47 19.5H31C29.5998 19.5 28.8997 19.5 28.365 19.2275C27.8945 18.9878 27.5123 18.6054 27.2725 18.135C27 17.6002 27 16.9001 27 15.5V7M19.5 7H39C41.8003 7 43.2005 7 44.27 7.54497C45.2108 8.02432 45.9757 8.78923 46.455 9.73005C47 10.7996 47 12.1997 47 15V39C47 41.8003 47 43.2005 46.455 44.27C45.9757 45.2108 45.2108 45.9757 44.27 46.455C43.2005 47 41.8003 47 39 47H10C7.19972 47 5.7996 47 4.73005 46.455C3.78923 45.9757 3.02432 45.2108 2.54497 44.27C2 43.2005 2 41.8003 2 39V15C2 12.1997 2 10.7996 2.54497 9.73005C3.02432 8.78923 3.78923 8.02432 4.73005 7.54497C5.73398 7.03345 7.02912 7.00205 9.5 7.00012M19.5 7V6C19.5 4.59987 19.5 3.8998 19.2275 3.36503C18.9878 2.89463 18.6054 2.51217 18.135 2.27247C17.6002 2 16.9001 2 15.5 2H13.5C12.0999 2 11.3998 2 10.865 2.27247C10.3946 2.51217 10.0122 2.89463 9.77248 3.36503C9.5 3.8998 9.5 4.59987 9.5 6V7.00012M19.5 7V33C19.5 34.4003 19.5 35.1003 19.2275 35.635C18.9878 36.1055 18.6054 36.4878 18.135 36.7275C17.6002 37 16.9001 37 15.5 37H13.5C12.0999 37 11.3998 37 10.865 36.7275C10.3946 36.4878 10.0122 36.1055 9.77248 35.635C9.5 35.1003 9.5 34.4003 9.5 33V7.00012M29.5 29.5H29.525V29.475H29.5V29.5ZM29.5 37H29.525V37.025H29.5V37ZM37 37H37.025V37.025H37V37ZM37 29.5H37.025V29.525H37V29.5Z" stroke="white" stroke-width="4" stroke-linecap="round" strokeLinejoin="round"/>
                                    </svg>


                                    <p className="roboto-condensed-regular p3 white_color">Phone</p>
                                </div>

                                <p className="credsContainer_para roboto-condensed-regular p3 white_color">{playersData[slideIndex].phoneNo}</p>
                            </div>
                            
                        </div>
                        <div className="player_bio mob_only" >
                                    <div className="player_bio_title">
                                        <h6 className="roboto-condensed-bold p2 white_color">Short Biography</h6>
                                    </div>
                                    <p className="roboto-condensed-regular p3 white_color">{biography} </p>
                        </div>
                    </div>
                </section>
            </section>
            
            
        </>
    )
}