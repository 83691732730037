import { useEffect,useState } from 'react';

function DataBattingTableHeader()
{
    return(
            <thead>
                 <tr>
                    <th style={{width:'4%',textAlign:'center'}} className="roboto-condensed-regular white_color p5" >#</th>
                    <th style={{width:'23%',textAlign:'left'}} className="roboto-condensed-regular white_color p5" >Player</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >Mat</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >Ins</th> 
                    <th className="roboto-condensed-regular white_color p5 border_th" >BF</th> 
                    <th className="roboto-condensed-regular white_color p5 border_th" >Rns</th> 
                    <th className="roboto-condensed-regular white_color p5 border_th" >4s</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >6s</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >50s</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >100s</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >NO</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >HS</th>
                </tr>   
            </thead>
    )
}

function DataBattingTableRow({tableRow,indexVal})
{
    
    return(
            <tr>
                <td style={{width:'4%',textAlign:'center'}} className="roboto-condensed-regular white_color p5" >{++indexVal}</td>
                <td style={{width:'23%',textAlign:'left'}} className="roboto-condensed-regular white_color p5" >{tableRow.firstName} {tableRow.lastName}</td>
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.matches}</td>
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.innings}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.ballsFaced}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.runsScored}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.fours}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.sixers}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.fifties}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.hundreds}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.notOuts}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.highestScore}</td>    
            </tr>  
    )
}



export default function RenderBattingDataTable({seriesTeamMappingArr})
{
    const [battingInfo, setBattingInfo] = useState([]);

    useEffect(() => {
        fetchBattingStats();
    }, [seriesTeamMappingArr]);

    async function fetchBattingStatsJSON() {
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/stats/getBattingStats?X-Auth-Token=&clubId=63&seriesId=${seriesTeamMappingArr.seriesId}&teamId=${seriesTeamMappingArr.teamId}&limit=1000`);
        const battingStats = await response.json();
        return battingStats;

    }

    function fetchBattingStats()
    {
        fetchBattingStatsJSON().then(battingStats => {
            
            setBattingInfo( battingStats.data );
           




        });
    }


    
    
    return(
            <table cellSpacing="0">
                <DataBattingTableHeader />
                
                <tbody>
                    {
                        battingInfo.map((tableRow,index)=>{
                            return(
                                    <DataBattingTableRow key={tableRow.playerID} tableRow={tableRow} indexVal={index} />
                            )
                        })
                    }
                </tbody>

            </table>
    )
}