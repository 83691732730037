import React, { useEffect, useState } from "react";
import LeagueStandings from "./LeagueStandings";
import NewFixturesAndResults from "./NewFixturesAndResults";
import PlayerOfTheWeek from "./PlayerOfTheWeek";
import NewLeagueLogoSlider from "./NewLeagueLogoSlider";
import LeagueHighlights from "./LeagueHighlights";
import NewNumberZone from "./NewNumberZone";

export default function LeagueStatsContainer() {
    const currentYear = new Date().getFullYear().toString();
    const [seriesTeamMappingArr, setSeriesTeamMappingArr] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const [battingInfo, setBattingInfo] = useState([]);
    const [bowlingInfo, setBowlingInfo] = useState([]);
    const [RankingInfo, setRankingInfo] = useState([]);
    const [LeagueInfo, setLeagueInfo] = useState([]);

    const flagImagesInadex = ["/images/flags/elite_30_30_flag.png","/images/flags/redball_flag.png","/images/flags/mpl_flag.png"];

    const [flagImage, setFlagImage] = useState("/images/flags/elite_30_30_flag.png")
    
    function handlePrevClick() {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex === 0 ? seriesTeamMappingArr.length - 1 : prevIndex - 1;
            const { seriesId, teamId } = seriesTeamMappingArr[newIndex];
            setFlagImage(flagImagesInadex[newIndex]);
            console.log(newIndex);
            fetchAllStats(seriesId, teamId);
            return newIndex;
        });
    }

    function handleNextClick() {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex === seriesTeamMappingArr.length - 1 ? 0 : prevIndex + 1;
            const { seriesId, teamId } = seriesTeamMappingArr[newIndex];
            setFlagImage(flagImagesInadex[newIndex]);
            fetchAllStats(seriesId, teamId);
            return newIndex;
        });
    }

    const fetchAllStats = (seriesId, teamId) => {
        fetchBattingStats(seriesId, teamId);
        fetchBowlingStats(seriesId, teamId);
        fetchRankingStats(seriesId, teamId);
        fetchLeagueStats(seriesId, teamId);
    };

    useEffect(() => {
        const fetchSeriesData = async () => {
            const seriesStats = await fetchAllSeriesJSON();
            const currentYearSeries = seriesStats.data.seriesList.filter((s) => s.year === currentYear);
        
            let seriesTeamMapping = [];
        
            for (const series of currentYearSeries) {
                const seriesId = series.seriesID;
                const teamIds = [2240, 2202, 2186]; // the three team IDs
        
                const teamData = await fetchTeamData(seriesId);
                
                if(teamData.data.teamsList !== null) {
                    teamData.data.teamsList.forEach(group => {
                        group.teams.forEach(team => {
                            if (teamIds.includes(team.teamID)) {
                                console.log(series.seriesName,series.seriesID)
                                seriesTeamMapping.push({ seriesId, teamId: team.teamID });
                            }
                        });
                    });
                }
            }
    
            // After all data is processed, update the state once
            console.log(seriesTeamMapping);
            setSeriesTeamMappingArr(seriesTeamMapping);
            
            if (seriesTeamMapping.length > 0) {
                const { seriesId, teamId } = seriesTeamMapping[0]; // Use the first item in the array
                fetchAllStats(seriesId, teamId);
            }
        };
    
        fetchSeriesData();
    }, []);

    async function fetchAllSeriesJSON() {
        const response = await fetch('https://ccapi.cricclubs.com/CCAPI/series/getSeriesList?X-Auth-Token=&clubId=63&limit=');
        const seriesStats = await response.json();
        return seriesStats;
    }

    async function fetchTeamData(seriesId) {
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/team/getTeamsList?clubId=63&seriesId=${seriesId}`);
        const teamData = await response.json();
        return teamData;
    }

    async function fetchBattingStatsJSON(seriesId, teamId) {
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/stats/getBattingStats?X-Auth-Token=&clubId=63&seriesId=${seriesId}&teamId=${teamId}&limit=1000`);
        const battingStats = await response.json();
        return battingStats;
    }

    async function fetchBowlingStatsJSON(seriesId, teamId) {
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/stats/getBowlingStats?X-Auth-Token=&clubId=63&seriesId=${seriesId}&teamId=${teamId}&limit=1000`);
        const bowlingStats = await response.json();
        return bowlingStats;
    }

    async function fetchRankingStatsJSON(seriesId, teamId) {
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/league/getPlayerRankings?X-Auth-Token=&clubId=63&seriesId=${seriesId}&teamId=${teamId}&limit=1000`);
        const rankingStats = await response.json();
        return rankingStats;
    }

    async function fetchLeagueStatsJSON(seriesId, teamId) {
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/match/getMatches?XAuthToken=&clubId=63&seriesId=${seriesId}&teamId=${teamId}&limit=1000`);
        const leagueStats = await response.json();
        return leagueStats;
    }

    function fetchBattingStats(seriesId, teamId) {
        fetchBattingStatsJSON(seriesId, teamId).then(battingStats => {
            setBattingInfo(battingStats.data);
        });
    }

    function fetchBowlingStats(seriesId, teamId) {
        fetchBowlingStatsJSON(seriesId, teamId).then(bowlingStats => {
            setBowlingInfo(bowlingStats.data);
        });
    }

    function fetchRankingStats(seriesId, teamId) {
        fetchRankingStatsJSON(seriesId, teamId).then(rankingStats => {
            setRankingInfo(rankingStats.data);
        });
    }

    function fetchLeagueStats(seriesId, teamId) {
        fetchLeagueStatsJSON(seriesId, teamId).then(leagueStats => {
            setLeagueInfo(leagueStats.data);
        });
    }

    return (
        <>
            {seriesTeamMappingArr.length > 0 ? (
                <section className="LSC_container base_paddings">
                    <div className="LSC_parent max_content center_aligned flex_grid">
                        <div className="LSC_smallCol_grid">
                            <div className="NewLeague_pag_icon_container">
                                <div className="flex">
                                    <div className="NewLeague_pag_icon prev_icon">
                                        <img onClick={handlePrevClick} src="/images/slide_pag_ico.png" alt="PAG ICON" />
                                    </div>
                                    <div className="NewLeague_pag_icon ">
                                        <img onClick={handleNextClick} src="/images/slide_pag_ico.png" alt="PAG ICON" />
                                    </div>
                                </div>
                            </div>
                            <NewLeagueLogoSlider flagImage={flagImage}  />  
                            <LeagueStandings /> 
                        </div>
                        <div className="LSC_BigCol_grid">
                            <PlayerOfTheWeek batterInfo={battingInfo[0]} bowlerInfo={bowlingInfo[0]} />
                            <LeagueHighlights battingInfo={battingInfo} bowlingInfo={bowlingInfo} RankingInfo={RankingInfo} LeagueInfo={LeagueInfo} />
                        </div>
                        <div className="LSC_smallCol_grid">
                            <NewFixturesAndResults seriesTeamMappingArr={seriesTeamMappingArr[currentIndex]}  fixtureCount={7} resultsCount={7} />
                        </div>
                    </div>
                    <NewNumberZone seriesTeamMappingArr={seriesTeamMappingArr[currentIndex]} />
                </section>
            ) : (
                <section className="LSC_container base_paddings">
                        <div className="loadingAnimation">
                            <img src="/images/loading.gif" alt=" ladoing GIF" />
                        </div>
                    </section>
            )}
        </>
    );
}
