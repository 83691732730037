import { useEffect,useState } from 'react';

function DataFeildingTableHeader()
{
    return(
            <thead>
                 <tr>
                    <th style={{width:'4%',textAlign:'center'}} className="roboto-condensed-regular white_color p5" >#</th>
                    <th style={{width:'23%',textAlign:'left'}} className="roboto-condensed-regular white_color p5" >Player</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >Mat</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >Cths</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >WC</th> 
                    <th className="roboto-condensed-regular white_color p5 border_th" >DR</th> 
                    <th className="roboto-condensed-regular white_color p5 border_th" >IDR</th> 
                    <th className="roboto-condensed-regular white_color p5 border_th" >STM</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >TO</th>
                    
                </tr>   
            </thead>
    )
}

function DataFeildingTableRow({tableRow,indexVal})
{
    
    return(
        <tr>
            <td style={{width:'4%',textAlign:'center'}} className="roboto-condensed-regular white_color p5" >{++indexVal}</td>
            <td style={{width:'23%',textAlign:'left'}} className="roboto-condensed-regular white_color p5" >{tableRow.firstName} {tableRow.lastName}</td>
            <td className="roboto-condensed-regular white_color p5 " >{tableRow.totalMatches}</td>
            <td className="roboto-condensed-regular white_color p5 " >{tableRow.catches}</td> 
            <td className="roboto-condensed-regular white_color p5 " >{tableRow.wkcatches}</td> 
            <td className="roboto-condensed-regular white_color p5 " >{tableRow.direct}</td> 
            <td className="roboto-condensed-regular white_color p5 " >{tableRow.indirect}</td> 
            <td className="roboto-condensed-regular white_color p5 " >{tableRow.stumpings}</td> 
            <td className="roboto-condensed-regular white_color p5 " >{tableRow.total}</td> 
    
        </tr>  
        )
}



export default function RenderFieldingDataTable({seriesTeamMappingArr})
{
    useEffect(()=>{fetchFieldingStats()}, [seriesTeamMappingArr]);

   
    const [fieldingInfo,setFieldingInfo]=useState([]);

    async function fetchFieldingStatsJSON() {
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/stats/getFeildingStats?X-Auth-Token=&clubId=63&seriesId=${seriesTeamMappingArr.seriesId}&teamId=${seriesTeamMappingArr.teamId}&limit=1000`);
        const feildingStats = await response.json();
        return feildingStats;

    }

    function fetchFieldingStats()
    {
        fetchFieldingStatsJSON().then(feildingStats => {
            
            setFieldingInfo( feildingStats.data );
           




        });
    }


    
    
    return(
            <table cellSpacing="0">
                <DataFeildingTableHeader />
                
                <tbody>
                    {
                        fieldingInfo.map((tableRow,index)=>{
                            return(
                                    <DataFeildingTableRow tableRow={tableRow} indexVal={index} />
                            )
                        })
                    }
                </tbody>

            </table>
    )
}