import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import RenderBowlingDataTable from './RenderBowlingDataTable';
import RenderBattingDataTable from './RenderBattingDataTable';
import RenderFieldingDataTable from './RenderFieldingDataTable';
import RenderRankingDataTable from './RenderRankingDataTable';




function NuberTable({seriesTeamMappingArr})
{    
    
    return(
            <div className="NZ_NT_container">
                    <Tabs>
                            <div className="NZ_NT_tabList_parent">
                                <TabList>
                                    <Tab className="roboto-condensed-bold react-tabs__tab p4 grey_text" >Batting</Tab>
                            
                                    <Tab className="roboto-condensed-bold react-tabs__tab p4 grey_text" >Bowling</Tab>
                                    <Tab className="roboto-condensed-bold react-tabs__tab p4 grey_text" >Fielding</Tab>
                            
                                    <Tab className="roboto-condensed-bold react-tabs__tab p4 grey_text" >Rankings</Tab>
                                </TabList>
                            </div>
                            <TabPanel className="react-tabs__tab-panel NZNT_table batting_table" >
                                <RenderBattingDataTable seriesTeamMappingArr={seriesTeamMappingArr} />
                            </TabPanel>   

                            <TabPanel className="react-tabs__tab-panel NZNT_table bowling_table" >
                                <RenderBowlingDataTable seriesTeamMappingArr={seriesTeamMappingArr} />    
                            </TabPanel>  

                            <TabPanel className="react-tabs__tab-panel NZNT_table fielding_table" >
                                <RenderFieldingDataTable seriesTeamMappingArr={seriesTeamMappingArr} />
                            </TabPanel>  

                            <TabPanel className="react-tabs__tab-panel NZNT_table rankings_table" >
                                <RenderRankingDataTable seriesTeamMappingArr={seriesTeamMappingArr} />
                            </TabPanel> 
                    </Tabs>  
            </div>
    )
}
export default function NewNumberZone({seriesTeamMappingArr})
{
    console.log("NUMBER ZONE:");
    console.log(seriesTeamMappingArr);
    return(
            <section className="NZ_container max_content center_aligned ">
                <div className="NZ_title">
                    <h5 className="oswald-bold p1 white_color" >NUMBER ZONE</h5>
                </div>
                <NuberTable seriesTeamMappingArr={seriesTeamMappingArr} />
            </section>
    )
}