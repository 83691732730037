import { useState,useEffect } from "react";

function DataBowlingTableHeader()
{
    return(
            <thead>
                <tr>
                    <th style={{width:'4%',textAlign:'center'}} className="roboto-condensed-regular white_color p5" >#</th>
                    <th style={{width:'23%',textAlign:'left'}} className="roboto-condensed-regular white_color p5" >Player</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >Mat</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >Ins</th> 
                    <th className="roboto-condensed-regular white_color p5 border_th" >Balls</th> 
                    <th className="roboto-condensed-regular white_color p5 border_th" >Runs</th> 
                    <th className="roboto-condensed-regular white_color p5 border_th" >Wkts</th> 
                    <th className="roboto-condensed-regular white_color p5 border_th" >Pts</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >Cths</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >4W</th> 
                    <th className="roboto-condensed-regular white_color p5 border_th" >5W</th> 
                    
                    <th className="roboto-condensed-regular white_color p5 border_th" >DB</th> 
                    
                </tr>    
            </thead>
    )
}

function DataBowlingTableRow({tableRow,indexVal})
{
    
    return(
            <tr>
                <td style={{width:'4%',textAlign:'center'}} className="roboto-condensed-regular white_color p5" >{++indexVal}</td>
                <td style={{width:'23%',textAlign:'left'}} className="roboto-condensed-regular white_color p5" >{tableRow.firstName} {tableRow.lastName}</td>
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.matches}</td>
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.innings}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.balls}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.runs}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.wickets}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.points}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.catches}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.fourWickets}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.fiveWickets}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.dotBalls}</td> 
               
                
            </tr>
    )
}



export default function RenderBowlingDataTable({seriesTeamMappingArr})
{
    useEffect(() => {
        fetchBowlingStats();
    }, [seriesTeamMappingArr]);

   const [bowlingInfo, setBowlingInfo] = useState([]);

    async function fetchBowlingStatsJSON() {
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/stats/getBowlingStats?X-Auth-Token=&clubId=63&seriesId=${seriesTeamMappingArr.seriesId}&teamId=${seriesTeamMappingArr.teamId}&limit=1000`);
        const bowlingStats = await response.json();
        return bowlingStats;

    }

    function fetchBowlingStats()
    {
        fetchBowlingStatsJSON().then(bowlingStats => {
           
            setBowlingInfo( bowlingStats.data );
           




        });
    }


    
    
    return(
            <table cellSpacing="0">
                <DataBowlingTableHeader />
                
                <tbody>
                    {
                        bowlingInfo.map((tableRow,index)=>{
                            return(
                                    <DataBowlingTableRow tableRow={tableRow} indexVal={index} />
                            )
                        })
                    }
                </tbody>

            </table>
    )
}